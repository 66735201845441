import api from '@/api';

export default {
  getFinanceCompanyList(params) {
    return new Promise((resolve, reject) => {
      api
        .get('/finances/company/', { params: params })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
  getFinanceCashierList(params) {
    return new Promise((resolve, reject) => {
      api
        .get('/finances/cashier/', { params: params })
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
}
