<template>
  <v-card tile>
    <v-list>
      <v-skeleton-loader
        v-if="!value"
        type="list-item-avatar"
      />
      <v-list-item v-else>
        <v-list-item-icon>
          <v-icon
            right
            :color="trendColor"
          >
            {{ trendIcon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ amount }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-subtitle v-text="labels[type]" />
          <v-list-item-subtitle
            v-if="label"
            v-text="label"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "FinanceCard",
  props: {
    value: Object,
    valueOld: Object,
    country: String,
    type: String,
  },
  data: () => ({
    labels: {
      proceeds: "Выручка",
      cost_price: "Себестоимость",
      margin: "Маржа",
    },
    beforeYesterday: new Date(new Date().setDate(new Date().getDate() - 2))
      .toISOString()
      .substr(0, 10),
    yesterday: new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .substr(0, 10),
  }),
  computed: {
    label() {
      if (!this.value) return "";
      let dateTxt = this.value.date
        ? new Date(Date.parse(this.value.date)).toLocaleString("ru", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        : "";
      dateTxt =
        this.value.date === this.yesterday
          ? "вчера"
          : this.value.date === this.beforeYesterday
          ? "позавчера"
          : dateTxt;
      return dateTxt ? `за ${dateTxt}` : "";
    },
    amount() {
      if (isNaN(this.value[this.type])) {
        return "нет данных";
      } else {
        return this.$options.filters.currency(this.value[this.type], this.country);
      }
    },
    trendIcon() {
      if (
        this.value &&
        this.valueOld &&
        !isNaN(this.value[this.type]) &&
        !isNaN(this.valueOld[this.type])
      ) {
        return this.value[this.type] > this.valueOld[this.type]
          ? "mdi-arrow-up-bold"
          : this.value[this.type] === this.valueOld[this.type]
          ? "mdi-equal"
          : "mdi-arrow-down-bold";
      }
      return "mdi-radiobox-blank";
    },
    trendColor() {
      if (
        this.value &&
        this.valueOld &&
        !isNaN(this.value[this.type]) &&
        !isNaN(this.valueOld[this.type])
      ) {
        return this.value[this.type] > this.valueOld[this.type]
          ? "green"
          : this.value[this.type] === this.valueOld[this.type]
          ? ""
          : "red";
      }
      return "white";
    },
  },
};
</script>
