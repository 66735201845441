<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        :rules="rules"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      :max="maxDate"
      scrollable
      :show-current="false"
      :first-day-of-week="1"
      @input="dialog = false"
    />
  </v-menu>
</template>

<script>
export default {
  props: {
    label: String,
    max: String,
    rules: Array,
    value: String,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    maxDate() {
      return this.max || new Date().toISOString().substr(0, 10);
    },
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
