<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-card-title>Данные по выручке</v-card-title>
      <v-card-subtitle>
        Установите параметры выборки. По умолчанию стоит за вчера.
      </v-card-subtitle>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            :md="isStaff ? 3 : 4"
          >
            <DatePicker
              v-model="date__gte"
              label="Дата начала"
              :rules="[validateDateGte]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            :md="isStaff ? 3 : 4"
          >
            <DatePicker
              v-model="date__lte"
              label="Дата окончания"
              :rules="[validateDateLte]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            :md="isStaff ? 3 : 4"
          >
            <v-select
              v-model="cashier"
              :items="getCashierList"
              :disabled="getCashierList.length < 3"
              label="Касса"
              hide-details
            />
          </v-col>
          <v-col
            v-if="isStaff"
            cols="12"
            sm="6"
            :md="isStaff ? 3 : 4"
          >
            <v-select
              v-model="country"
              :items="getCountryList"
              label="Страна"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row v-if="loadingData">
          <v-col
            v-for="index in 3"
            :key="index"
            cols="12"
            sm="6"
            md="4"
          >
            <FinanceCard />
          </v-col>
        </v-row>
        <v-row
          v-for="(day, index) in financeAggregate
            ? getfinanceItems
            : getfinanceItems.slice(0, -1)"
          :key="index"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <FinanceCard
              type="proceeds"
              :value="getfinanceItems[index]"
              :value-old="getfinanceItems[index + 1]"
              :country="country"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <FinanceCard
              type="cost_price"
              :value="getfinanceItems[index]"
              :value-old="getfinanceItems[index + 1]"
              :country="country"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <FinanceCard
              type="margin"
              :value="getfinanceItems[index]"
              :value-old="getfinanceItems[index + 1]"
              :country="country"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="10"
            offset-sm="1"
          >
            <LineChart
              v-if="!loadingData && getChartItems.labels.length > 1"
              :chart-data="getChartItems"
              :options="chartOptions"
              :styles="{ height: '30em', position: 'relative' }"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import finance from "@/api/finance";
import DatePicker from "@/components/DatePicker";
import FinanceCard from "@/components/FinanceCard";
import LineChart from "@/components/charts/LineChart";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FinanceView",
  components: {
    DatePicker,
    FinanceCard,
    LineChart,
  },
  data: () => ({
    loadingData: true,

    cashier: "",

    country: "RU",

    date__gte: new Date(new Date().setDate(new Date().getDate() - 2))
      .toISOString()
      .substr(0, 10),
    date__lte: new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .substr(0, 10),
    financeAggregate: false,

    financeItems: [],

    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "График доходов и расходов за указанный период",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Дата",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Сумма",
            },
          },
        ],
      },
    },
  }),
  computed: {
    ...mapGetters("auth", ["isStaff", "getUseCountry"]),
    ...mapGetters("clients", [
      "getCashierList",
      "getCountryList",
      "getUserCompany",
    ]),
    getfinanceItems() {
      if (!this.financeAggregate || this.financeItems.length === 0)
        return this.financeItems;
      return [
        this.financeItems.reduce(
          (acc, value) => ({
            proceeds:
              acc.proceeds +
              (isNaN(Number(value.proceeds)) ? 0 : Number(value.proceeds)),
            cost_price:
              acc.cost_price +
              (isNaN(Number(value.cost_price)) ? 0 : Number(value.cost_price)),
            margin:
              acc.margin +
              (isNaN(Number(value.margin)) ? 0 : Number(value.margin)),
          }),
          { proceeds: 0, cost_price: 0, margin: 0 }
        ),
      ];
    },
    getChartItems() {
      let financeChartItems = Array.from(this.financeItems).reverse();
      financeChartItems = this.financeAggregate
        ? financeChartItems
        : financeChartItems.slice(1);
      return {
        labels: financeChartItems.map((el) =>
          new Date(Date.parse(el.date)).toLocaleString("ru", {
            month: "numeric",
            day: "numeric",
          })
        ),
        datasets: [
          {
            label: "Выручка",
            borderColor: "rgb(0, 200, 0)",
            lineTension: 0,
            fill: false,
            data: financeChartItems.map((el) => el.proceeds),
          },
          {
            label: "Себестоимость",
            borderColor: "rgb(255, 0, 0)",
            lineTension: 0,
            fill: false,
            data: financeChartItems.map((el) => el.cost_price),
          },
          {
            label: "Маржа",
            borderColor: "rgb(0, 0, 200)",
            lineTension: 0,
            fill: false,
            data: financeChartItems.map((el) => el.margin),
          },
        ],
      };
    },
    validateDateGte() {
      if (Date.parse(this.date__gte) > Date.parse(this.date__lte)) {
        return "Дата начала не может быть больше даты окончания";
      } else {
        return true;
      }
    },
    validateDateLte() {
      if (Date.parse(this.date__gte) > Date.parse(this.date__lte)) {
        return "Дата начала не может быть больше даты окончания";
      } else {
        return true;
      }
    },
  },
  watch: {
    date__gte() {
      this.validateAndUpdateFinanceData();
    },
    date__lte() {
      this.validateAndUpdateFinanceData();
    },
    cashier() {
      this.validateAndUpdateFinanceData();
    },
    country() {
      this.validateAndUpdateFinanceData();
    },
    getUserCompany() {
      this.loadingData = true;
      this.cashier = "";
      this.updateFinanceData();
    },
    getUseCountry(v) {
      this.country = v;
    },
    getCashierList(v) {
      // если одна точка продаж, то делаем её по умолчанию
      this.cashier = v.length == 2 ? v[1].value : "";
    },
  },
  created() {
    if (!this.isStaff) {
      this.country = this.getUseCountry;
    }
    this.updateCashierList();
    this.updateFinanceData();
  },
  methods: {
    ...mapActions("clients", ["updateCashierList"]),
    updateFinanceData: async function () {
      this.loadingData = true;
      this.financeItems = [];
      let date__gte = new Date(Date.parse(this.date__gte));
      let date__lte = new Date(Date.parse(this.date__lte));
      // Если разница день или меньше, то запрашиваем на день больше, чтобы показать тренд
      if (date__lte - date__gte <= 1000 * 3600 * 24) {
        date__gte = new Date(date__gte.setDate(date__gte.getDate() - 1));
        this.financeAggregate = false;
      } else {
        this.financeAggregate = true;
      }
      date__gte = date__gte.toISOString().substr(0, 10);
      await finance
        .getFinanceCashierList({
          country: this.country,
          company: this.getUserCompany,
          cashier: this.cashier,
          date__lte: this.date__lte,
          date__gte,
        })
        .then((response) => {
          this.financeItems = response.data.map((el) => ({
            ...el,
            proceeds: Number.parseFloat(el.proceeds),
            cost_price: Number.parseFloat(el.cost_price),
            margin: Number.parseFloat(el.margin),
          }));
        })
        .finally(() => (this.loadingData = false));
    },
    validateAndUpdateFinanceData: function () {
      if (
        !this.loadingData &&
        Date.parse(this.date__gte) <= Date.parse(this.date__lte)
      )
        this.updateFinanceData();
    },
  },
};
</script>
